{
  "BUTTON_YES": "Yes",
  "BUTTON_NO": "No",
  "BUTTON_OK": "OK",
  "BUTTON_CANCEL": "Cancel",
  "BUTTON_SAVE": "Save",
  "BUTTON_SEND": "Send",
  "TOOLTIPS": {
    "LOGOUT": "Log out",
    "ARCHIVE_CHAT": "Archive chat",
    "CHAT_INFORMATION": "Information",
    "EXPORT_CHAT_AS_PDF": "Export chat as PDF",
    "EXPORT_INSIGHT_AS_PDF": "Export insight as PDF"
  },
  "LOGIN": {
    "TITLE": "Log in to your {{TYPE}} account",
    "EMAIL": "Email",
    "PASSWORD": "Password",
    "REMEMBER": "Remember my email address",
    "ERROR": {
      "INACTIVITY": "You have been logged out due to inactivity.",
      "MISMATCH": "You have been logged out due to a change in your configuration.",
      "CREDENTIALSNOTFOUND": "The email address or password you entered is incorrect.",
      "UNKNOWN": "An unknown error occurred. Please try again later.",
      "2FA_INVALID": "The code you entered is incorrect.",
      "NO2FA": "No 2FA is not enabled for this account."
    }
  },
  "WEBCHAT": {
    "WELCOME_BACK": "Welcome back to our chat",
    "WELCOME": "Welcome to our chat",
    "EMERGENCY": "In case of emergency, dial 112 or call our counseling center.",
    "CHOOSE_USERNAME": "Choose username",
    "FILL_IN_PIN": "Fill in your PIN",
    "FORGOT_PIN": "Forgot your PIN? Start a new conversation.",
    "I_ACCEPT_THE_TERMS": "I accept the",
    "TERMS": "terms of condition",
    "CHOOSE_PIN": "Choose PIN to secure your chat",
    "OUR_TEAM": "Our Counselors",
    "OUR_TEAM_TEXT": "Send us a message so we can to help you. ",
    "COUNSELOR": "Counselor",
    "BUTTON_LOGIN": "Login",
    "LOGOUT": "Lock Chat",
    "LOADING": "Loading...",
    "REFLECTION": "Reflection",
    "REFLECTION_SPARE_TIME": "Spare Time",
    "REFLECTION_WORK": "Work",
    "REFLECTION_FEELINGS": "Emotions",
    "REFLECTION_MOODS": "Moods",
    "SEND": "Send",
    "ANSWER": "Reply",
    "ESTABLISHING_SECURE_CONNECTION": "Establishing secure connection ",
    "UNWANTED_BEHAVIOUR": "Undesirable Behaviour",
    "FORGOT_PIN_TITLE": "Forgot your PIN?",
    "FORGOT_PIN_TEXT": "You have forgotten your PIN. Without this PIN you can no longer access your previous chat. You can start a new chat. Do you want to do that?"
  },
  "STATUS": {
    "AVAILABLE": "Available",
    "UNAVAILABLE": "Unavailable"
  },
  "CHAT": {
    "WELCOME": "Welcome",
    "PENDING": "Pending",
    "NEW_CHATS": "New chats",
    "SHOW_ARCHIVE": "Show archive",
    "ARCHIVE": "Archive",
    "COUNSELORS": "Online",
    "COUNSELORS_IN_THIS_CHAT": "Counselors in this chat",
    "NONE": "None",
    "CHAT_INFORMATION": "",
    "ENABLE_PUSH_NOTIFICATIONS": "Enable push notifications",
    "NO_CHATS": "No chats",
    "NO_NAME": "No name",
    "MY_CHATS": "My chats",
    "RECONNECTING": "Reconnecting...",
    "TYPE_MESSAGE": "Type your message here...",
    "SAVE": "Save",
    "ENCRYPTION": {
      "CHANGE_ENCRYPTION_KEY": "",
      "ENCRYPTION_KEY": "",
      "ENCRYPTION_KEY_REPEAT": ""
    },
    "MODAL_ARCHIVE": {
      "TITLE": "Archive chat",
      "TEXT": "Are you sure you want to archive this chat?"
    },
    "MODAL_CHANGE_STATUS": {
      "TITLE": "Active chats",
      "TEXT": "You still have active chats. Do you want to continue?"
    },
    "USER_FORGOT_PIN": "Note: the user has indicated that he/she has forgotten the PIN code of the app. The user can no longer access the chat and may enter under a new name."
  },
  "AUTOMATIC_RESPONSE": {
    "TITLE": "Automatic messages",
    "SCHEDULED_TITLE": "Automatic message about privacy",
    "GERMAN": "German",
    "ENGLISH": "English",
    "DURING_WORKING_HOURS_TITLE": "Automatic message out of office",
    "DURING_WORKING_DAYS": "On working days",
    "DURING_WEEKEND": "In the weekends",
    "DEFINE_WORKING_HOURS": "Define working hours",
    "TIME_FROM": "from",
    "TIME_TO": "till"
  },
  "LANGUAGE_NL": "Dutch",
  "LANGUAGE_EN": "English",
  "LANGUAGE_DE": "German",
  "LANGUAGE_FR": "French",
  "LANGUAGE_IT": "Italian",
  "LANGUAGE_ES": "Spanish",
  "UNKNOWN": "Unknown",
  "INSIGHTS": {
    "CHOOSE_COMPANY": "Choose company",
    "ALL_COMPANIES": "All companies",
    "CHOOSE_DEPARTMENT": "Choose department",
    "ALL_DEPARTMENTS": "All departments",
    "DOWNLOAD_SCREENS_STATISTICS_CSV": "Download screen statistics (.CSV)",
    "DOWNLOAD_ARTICLE_STATISTICS_CSV": "Download article statistics (.CSV)",
    "DOWNLOAD_USER_STATISTICS_XLSX": "Download user statistics (.XLSX)",
    "GROUP_BY": "Group by",
    "GROUP_BY_VALUES": {
      "PER_DAY": "day",
      "PER_MONTH": "month",
      "PER_YEAR": "year"
    },
    "CHOOSE_DATE_RANGE": "Enter a date range (optional)",
    "HEADERS": {
      "AGE": "Age",
      "SEX": "Gender",
      "LANGUAGE": "Language",
      "LOGINS": "Logins",
      "SCREEN_VIEWS": "Screen views",
      "USER_ACTIVES": "User actives"
    },
    "TABLE_HEADERS": {
      "DATE_SELECTION": "Date selection",
      "COMPANY_NAME": "Company name",
      "DEPARTMENT_NAME": "Department name",
      "SCREEN_NAME": "Screen name",
      "ARTICLE_ID": "Article ID",
      "NUM": "Count",
      "CODE": "Code",
      "TITLE_EN": "Title (EN)",
      "TITLE_DE": "Title (DE)",
      "TITLE_NL": "Title (NL)"
    },
    "USER_STATISTICS": "User statistics",
    "SCREEN_STATISTICS": "Screen statistics",
    "USAGE_STATISTICS": "Usage statistics",
    "ARTICLE_STATISTICS": "Article statistics",
    "NO_STATISTICS_WITH_TYPE": "No statistics available for {{TYPE}} with the current selection."
  },
  "CHANGE_PASSWORD": {
    "TITLE": "Change password",
    "CURRENT_PASSWORD": "Current password",
    "NEW_PASSWORD": "New password",
    "CONFIRM_PASSWORD": "Confirm new password",
    "WARNING_PGP": "Warning: if you change your password, we will generate a new PGP key, which means you will no longer have access to your old messages.",
    "ERROR_MIN_LENGTH": "Password must be at least 8 characters long",
    "ERROR_LOWERCASE": "Password must contain at least one lowercase letter",
    "ERROR_UPPERCASE": "Password must contain at least one uppercase letter",
    "ERROR_DIGIT": "Password must contain at least one digit",
    "ERROR_SPECIAL_CHAR": "Password must contain at least one special character (!@#$%^&*)",
    "PASSWORD_REQUIREMENTS": "Password requirements",
    "PASSWORD_MISMATCH": "Passwords do not match"
  },
  "SETTINGS": {
    "CHANGE_PASSWORD": "Change password"
  }
}
